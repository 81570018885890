<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Saring Nama"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-3" v-if="roleId == 2 && secondPurpose != 'addParticipant'">
        <b-input-group>
          <treeselect
            v-model="filter.major_id"
            :multiple="false"
            placeholder="Pilih Jurusan"
            :options="majors"
            @input="filterByMajor"
          />
        </b-input-group>
      </div>
      <div class="col-md-3" v-if="roleId == 2 && secondPurpose != 'addParticipant'">
        <b-input-group>
          <treeselect
            v-model="filter.school_year_id"
            :multiple="false"
            placeholder="Pilih Angkatan"
            :options="school_years"
            @input="filterBySchoolYear"
          />
        </b-input-group>
      </div>
      <div class="col-md-3" v-if="roleId == 2 && secondPurpose == 'addParticipant' && filterParticipant.role_id == 2">
        <b-input-group>
          <treeselect
            v-model="filter.class_group_id"
            :multiple="false"
            placeholder="Kelompok Kelas"
            :options="class_groups"
            @input="filterByClassGroup"
          />
        </b-input-group>
      </div>
    </div>
    <div class="d-flex justify-content-end" v-if="secondPurpose=='addParticipant'">
      <div class="">
        <b-form-checkbox
          checked=""
          :id="`checkbox-pick-item-check-all`"
          :name="`checkbox-pick-item-check-all`"
          @change="checkAll"
          >Pilih Semua
        </b-form-checkbox>
      </div>
      <div class=""></div>
    </div>
    <!-- table -->
    <b-table
      striped
      hover
      :items="items"
      :fields="
        roleId == 1 || filterParticipant.role_id == 1
          ? fieldsTeacher
          : roleId == 2 || purpose == 'modal-user'
          ? fieldsStudent
          : fields
      "
      responsive
      class="mt-3"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '25%' : field.key === 'major_name' ? '5%' : '' }"
        />
      </template>

      <template #cell(photo)="data">
        <div
          class="
            d-flex
            justify-content-start
            align-items-center
            image-container
          "
        >
          <div class="image-input-wrapper" style="position: relative">
            <img class="image" :src="data.item.photo" />
          </div>
        </div>
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({
                path: '/masters/users/detail/' + data.item.id,
              })
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="
              $router.push({
                path: '/masters/users/edit/' + data.item.id,
              })
            "
            v-if="btnAccess"
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            @click="deleteData(data.item.id)"
            v-if="btnAccess"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
        <template v-if="purpose == 'master-teachers'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({
                path: '/masters/users/teachers/detail/' + data.item.id,
              })
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="
              $router.push({
                path: '/masters/users/teachers/edit/' + data.item.id,
              })
            "
            v-if="btnAccess"
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            @click="deleteData(data.item.id)"
            v-if="btnAccess"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
        <template v-if="purpose == 'master-students'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({
                path: '/masters/users/students/detail/' + data.item.id,
              })
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="
              $router.push({
                path: '/masters/users/students/edit/' + data.item.id,
              })
            "
            v-if="btnAccess"
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            @click="deleteData(data.item.id)"
            v-if="btnAccess"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
        <template v-if="purpose == 'modal-user'">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="
              chooseUser({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })
            "
          >
          </b-form-checkbox>
        </template>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    purpose: String,
    secondPurpose: String,
    route: String,
    checkbox: Boolean,
    selectedItem: Array,
    filterParticipant: Object,
    roleId: String,
  },
  name: "master-users",
  data() {
    return {
      // filter
      filter: {
        name: "",
        role_id: "",
        major_id: "",
        school_year_id: "",
        class_group_id: "",
      },
      majors: [],
      school_years: [],
      class_groups: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: 'nowrap'
        },
      ],
      fieldsTeacher: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIP/NIK",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: 'nowrap'
        },
      ],
      fieldsStudent: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIS",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "major_name",
          label: "Jurusan",
          sortable: true,
        },
        {
          key: "school_year_name",
          label: "Angkatan",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: 'nowrap'
        },
      ],
      items: [],
      // other
      check_all: false,
      all_user_data: [],
      all_user_data_form: {
        id: "",
        name: "",
        role: ""
      },
      // access
      btnAccess: false
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      if (this.purpose === "modal-user") {
        this.filter.role_id = this.filterParticipant.role_id;
      } else if (this.purpose === "master-teachers") {
        this.filter.role_id = this.roleId;
      } else if (this.purpose === "master-students") {
        this.filter.role_id = this.roleId;
      }

      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}&major_id=${this.filter.major_id}&school_year_id=${this.filter.school_year_id}&class_group_id=${this.filter.class_group_id}`;
      let response = await module.paginate(
        this.route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

      if (this.selectedItem) {
        //("betull sekali", this.selectedItem);
        this.setCheckedItem();
      }
    },

    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.majors = response.data;
        this.majors.unshift({
          label: "Pilih Jurusan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSchoolYearOption() {
      let currentTime = new Date().toISOString().substr(0, 10);
      let filterParams = ``;
      let response = await module.setTreeSelect(
        `api/school-years`,
        `?page=${this.currentPage}&page_size=20${filterParams}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Pilih Angkatan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getClassGroupOption() {
      let currentTime = new Date().toISOString().substr(0, 10);
      let filterParams = `now=${currentTime}`;
      let response = await module.setTreeSelect(
        `api/class-groups/available`,
        `?${filterParams}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_groups = response.data;
        this.class_groups.unshift({
          label: "Kelompok Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.pagination();
    },

    async filterByMajor(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.major_id = await "";
        this.pagination();
      } else {
        this.filter.major_id = await evt;
        this.pagination();
        //("ok", this.filter.major_id);
      }
    },

    async filterBySchoolYear(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.school_year_id = await "";
        this.pagination();
      } else {
        this.filter.school_year_id = await evt;
        this.pagination();
        //("ok", this.filter.school_year_id);
      }
    },

    async filterByClassGroup(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.class_group_id = await "";
        this.pagination();
      } else {
        this.filter.class_group_id = await evt;
        this.pagination();
      }
    },

    chooseUser(value) {
      this.$emit("chooseUser", value);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setCheckedItem() {
      let found;
      this.items.forEach((value) => {
        found = this.selectedItem.find((x) => x.id == value.id);
        if (typeof found === "undefined") {
          value.checked = false;
        } else {
          value.checked = true;
        }
      });
    },

    checkAll() {
      if (this.check_all == false) {
        this.check_all = true;
        this.setCheckAll(true)
        this.$emit("checkAll", this.all_user_data)
      }else{
        this.check_all = false
        this.setCheckAll(false)
        this.$emit("checkAll", this.all_user_data)
      }
    },
    setCheckAll(check){
      for (let a = 0; a < this.items.length; a++) {
          this.items[a].checked = check;
          this.all_user_data_form.id = this.items[a].id
          this.all_user_data_form.name = this.items[a].name
          this.all_user_data_form.role = this.items[a].role_id
          let clone = { ...this.all_user_data_form };
          this.all_user_data.push(clone)

          if(a + 1 == this.items.length && check == false){
            this.all_user_data = []
          }
      }
      //("all user data", this.all_user_data)
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    if (this.purpose === "modal-user") {
      this.pagination();
      //("harusnya ini gak jalan");
    }

    this.getMajorOption();
    this.getSchoolYearOption();
    this.getClassGroupOption();
    this.checkAccess();

    this.$root.$on("getUserByRole", (role_id) => {
      // reset form on new (tambah data)
      this.filter.role_id = role_id;
      this.pagination();
      //("run emit get user by role");
    });
  },
  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem();
    },
    roleId: function (newVal) {
      this.pagination();
    },
  },
};
</script>

<style>
.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nowrap{
  white-space: nowrap;
}

.sr-only {
  width: 0px !important;
}
</style>